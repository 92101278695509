import classNames from 'classnames'
import React from 'react'

import ContentfulRichText from '../../_elements/ContentfulRichText'
import LayoutContainer from '../../_layouts/LayoutContainer'
import RichTextLayout from '../../_layouts/RichTextLayout'
import HeroHeading from '../../_typography/HeroHeading'

import styles from './services-hero.module.scss'

interface ServicesHeroProps {
  title: string | null | undefined
  text?: { json: any } | null

  className?: string
}

export default function ServicesHero({
  title,
  text,

  className,
}: ServicesHeroProps) {
  return (
    <LayoutContainer className={classNames(styles.container, className)}>
      <HeroHeading
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title ?? '' }}
      />

      {text && (
        <RichTextLayout className={styles.text}>
          <ContentfulRichText json={text.json} />
        </RichTextLayout>
      )}
    </LayoutContainer>
  )
}
