import { graphql } from 'gatsby'
import React from 'react'

import { ServicesPageType } from '../../@import-types/contentful/ServicesPage.types'
import ContentArea from '../../components/ContentArea'
import Layout from '../../components/Layout'
import ServicesHero from '../../components/_heroes/ServicesHero'
import TabsContainer from '../../components/_layouts/TabsContainer'
import { RootPageQuery } from '../../graphql/global.graphql'

type ServicesPageProps = RootPageQuery<{
  contentfulServicesPage: ServicesPageType
}>

export default function ServicesPage({ data, pageContext }: ServicesPageProps) {
  const { title, heroText, servicesContent } = data.contentfulServicesPage

  return (
    <Layout
      data={data}
      basePageContent={data.contentfulServicesPage}
      pageContext={pageContext}
    >
      <ServicesHero title={title} text={heroText} />

      {/* Not using the tabsections for services page for now */}
      {/* {servicesContent && <TabsContainer tabSections={servicesContent} />} */}
      {servicesContent?.map((section) => (
        <>
          {section.title && section.title != ' ' && <h2>{section.title}</h2>}
          <ContentArea content={section.content!} />
        </>
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServicesPageById(
    $id: String!
    $startPageId: String!
    $locale: String!
  ) {
    contentfulServicesPage(id: { eq: $id }) {
      ...BasePageFragment

      title

      heroText {
        json
      }

      servicesContent {
        __typename
        id

        title
        content {
          __typename

          ... on Node {
            ...ContentAreaFragment
          }
        }
      }
    }

    ...GlobalFragment
  }
`
